<template>
	<div class="enterprise">
		<div class="breadcrumbTop">
			<a-breadcrumb>
				<a-breadcrumb-item @click='$router.back()'>自定义设置</a-breadcrumb-item>
				<a-breadcrumb-item><a href="">合同类型设置</a></a-breadcrumb-item>
			</a-breadcrumb>
			<div class="tips_top_box" v-show="visit2">
				<p class="tips_titele_box"> <span class="tips_title">使用指南</span> <span class="close_open"
						@click='visit2=false'>关闭提示</span></p>

				<p>1、您添加和禁用的类型，在所有的合同类型字段处都同步应用</p>
				<p>2、设置合同类型适用的工作性质，将会应用于人事提醒、用工风险监测中的合同未签订、合同即将到期提醒</p>
			</div>
			<div style="text-align: right;">
				<a-button class='pointerCancel' @click='mergeVisible=true'>添加合同类型
				</a-button>
			</div>
			<div class="FieldSettingsBox">
				<div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
					<span>
						&nbsp; &nbsp;
					</span>
					<span>合同类型</span>
					<span>适用的工作性质</span>
					<span style="color: #666;">启用/禁用</span>
					<span style="color: #666;">操作</span>
				</div>
				<div class="FieldSettingsBoxTitle" v-show="element.status==-1" v-for="element in all_list">
					<span>
						&nbsp; &nbsp;
					</span>
					<span>
						{{element.name}}
					</span>
					<span class="cq_text_require">
						{{element.nature_name}}
					</span>
					<span class="cq_text_require" @click="change_status(element.id)">
						<a-switch v-model:checked="element.status_check" />
					</span>
					<span> <span class="cq_color" v-if="element.can_edit==1" @click='editInfo(element)'>编辑</span>
						&nbsp;&nbsp;&nbsp;
						<a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="delOk(element.id)"
							@cancel="cancel">
							<a href="#" v-if="element.can_delete==1" class="cq_error">删除</a>
						</a-popconfirm>
					</span>
				</div>
				<!-- 	<draggable v-model="all_list" chosenClass="chosen" forceFallback="true" group="people" animation="1000"
					@start="onStart" @end="onEnd">
					<transition-group> -->
				<div :draggable="true" @dragstart="dragstart($event, -1, index)" @dragover="dragover"
					@dragenter="dragenter($event, -1, index)" @dragend="dragend($event, -1, index)" @drop="drop"
					v-show="element.status==1" class="FieldSettingsBoxTitle" v-for="(element,index) in all_list"
					:key="element.id">
					<span>
						<a-icon type="unordered-list" />
					</span>
					<span>
						{{element.name}}
					</span>
					<span>
						{{element.nature_name}}
					</span>
					<span>
						<a-switch :disabled="element.can_forbid==-1" @click='change_status(element.id)'
							v-model:checked="element.status_check" />
					</span>
					<span> <span class="cq_color" v-if="element.can_edit==1" @click='editInfo(element)'>编辑</span>
						&nbsp;&nbsp;&nbsp;
						<a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="delOk(element.id)"
							@cancel="cancel">
							<a href="#" v-if="element.can_delete==1" class="cq_error">删除</a>
						</a-popconfirm>
					</span>
				</div>
				<!-- </transition-group>
				</draggable> -->
				<!-- <div v-show="element.status==-1" class="FieldSettingsBoxTitle" v-for="element in all_list"
							:key="element.id">
							<span>
									&nbsp; &nbsp;
							</span>
							<span>
								{{element.name}}
							</span>
							<span>
								{{element.nature_name}}
							</span>
							<span>
								<a-switch :disabled="element.is_forbid==-1" @click='change_status(element.id)'
									v-model:checked="element.status_check" />
							</span>
							<span> <span class="cq_color" @click='editInfo(element)'>编辑</span>
							&nbsp;&nbsp;&nbsp;
								<a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="delOk(element.id)"
									@cancel="cancel">
									<a href="#" v-if="element.is_custom==1" class="cq_error">删除</a>
								</a-popconfirm>
							</span>
						</div> -->
			</div>
		</div>


		<a-modal v-model:visible="mergeVisible" :title="editType=='edit'?'编辑合同类型':'新增合同类型'" @ok="addOk">
			<a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item label="合同类型">
					<a-input v-model:value="formState2.name" />
				</a-form-item>
				<a-form-item label="适用工作性质">
					<a-select mode="multiple" v-model:value="formState2.nature_ids" placeholder="请选择合同">
						<a-select-option v-for='item in select_list' :value="String(item.value)">
							{{item.label}}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="合同备注">
					<a-input v-model:value="formState2.remark" />
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import {
		editCustomSetting,
		editCustomSettingCate,
		getCustomSettingInfo,
		getViewBookSelect,
		addCustomSetting,
		delCustomSetting,
		getCustomSettingDetail,
		changeCustomSettingStatic,
		changeCustomSettingStatus,
		sortCustomSetting,
		addCustomSettingCate,
	} from '@/api/enterprise.js'
	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';


	export default {
		components: {

		},
		data() {
			return {
				stop_index: 0,
				stop_approval_index: 0,
				all_list: [],
				visit2: true,
				editType: 'add',
				company_id: '',
				company_name: '',
				merge_company: '',
				addressLitst: [],
				delVisible: false,
				editVisible: false,
				mergeVisible: false,
				delCompany: false,

				labelColEdit: {
					span: 6
				},
				wrapperColEdit: {
					span: 18
				},
				wrapperCol: {
					span: 12
				},
				formState2: {},
				select_list: [],
				type: '',
			}

		},
		created() {
			this.type = this.$route.query.type
			this.getIndustry()
		},
		methods: {
			dragstart(e, ind) {
				// e.preventDefault()
			},
			drop(e) {
				e.preventDefault()
			},
			// 拖拽位置
			dragenter(e, ind, index) {
				e.preventDefault()
				if (ind != -1) {
					this.stop_index = ind;
				}
				this.stop_approval_index = index
			},
			dragover(e) {
				e.preventDefault()
			},
			// 拖拽完成
			dragend(e, ind, index) {
				let list = this.all_list;
				let move_data = list[index];
				list.splice(index, 1)
				list.splice(this.stop_approval_index, 0, move_data)
				this.all_list = list;
				this.onEnd()

			},
			cancel() {},
			change_forbid(id) {
				changeCustomSettingStatic({
					data: {
						id: id
					},
					info: true
				}).then(res => {
					this.getIndustry()
				})
			},
			change_status(id) {
				changeCustomSettingStatus({
					data: {
						id: id
					},
					info: true
				}).then(res => {
					this.getIndustry()
				})
			},
			onStart() {
				this.drag = true;
			},
			onEnd() {
				this.drag = false;
				let sort = []
				this.all_list.forEach(item => {
					sort.push(item.id)
				})
				sortCustomSetting({
					data: {
						sort: sort.join(',')
					},
					info: true
				}).then(res => {
					this.getIndustry()
				})
			},
			editInfo(item) {
				this.formState2 = {
					name: item.name,
					nature_ids: item.nature_ids.split(','),
					remark: item.remark,
					id: item.id
				}
				this.editType = 'edit'
				this.mergeVisible = true
			},
			addOk() {
				if (this.editType == 'add') {
					addCustomSetting({
						data: {
							type: this.type,
							name: this.formState2.name,
							nature_ids: this.formState2.nature_ids.length > 0 ? this.formState2.nature_ids.join(
								',') : '',
							remark: this.formState2.remark,
						},
						info: true
					}).then(res => {
						this.editType = 'add'
						this.formState2 = {}
						this.mergeVisible = false
						this.getIndustry()
					})
				} else {
					editCustomSetting({
						data: {
							id: this.formState2.id,
							type: this.type,
							name: this.formState2.name,
							nature_ids: this.formState2.nature_ids.length > 0 ? this.formState2.nature_ids.join(
								',') : '',
							remark: this.formState2.remark,
						},
						info: true
					}).then(res => {
						this.editType = 'add'
						this.mergeVisible = false
						this.formState2 = {}
						this.getIndustry()
					})
				}

			},
			delOk(id) {
				delCustomSetting({
					data: {
						id: id
					},
					info: true
				}).then(res => {
					this.getIndustry()
				})
			},
			get_CustomSettingCateSelect() {
				getViewBookSelect().then(res => {
					this.select_list = res.data.list
				})
			},
			getIndustry() {
				getCustomSettingDetail({
					data: {
						type: this.type
					},
				}).then(res => {
					res.data.list.content.forEach(item => {
						item.is_forbid_check = item.is_forbid == 1 ? true : false
						item.status_check = item.status == 1 ? true : false
					})
					this.all_list = res.data.list.content
				})
				this.get_CustomSettingCateSelect()
			},
			handle_submit() {

			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.breadcrumbTop {
		flex: 1;
	}

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.FieldSettingsBox {
		border: 1px solid @cq_line_e;
		margin-top: 12px;
	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(2) {
			flex: 2;
			margin-left: 8px;
		}

		span:nth-child(3) {
			flex: 3;
		}

		span:nth-child(4) {
			color: #fff;
			flex: 1;

		}

		span:nth-child(5) {
			color: #fff;
			width: 120px;
			text-align: left;

		}
	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;

		span:nth-child(4) {
			color: #666;
		}

	}
</style>